'use strict';

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
	homeshow: function () {},
	productshow: function () {},
    productshowincategory: function () {},
	searchshow: function () {},
	cartshow: function () {
		$('body').on('gtm:qtyUpdate', function(e, uuid) {
			var $lineItem = $('.uuid-'+ uuid);
			var gmtData = $lineItem.find('.remove-product').data('gtmdata') || $.parseJSON($lineItem.find('.remove-product').attr('data-gtmdata'));
			var qty = $lineItem.find('.quantity').val();
			addToCart(gmtData, qty);
		});
	},
	checkoutbegin: function () {},
	orderconfirm: function() {
		var gtmdata = $('.confirm-details').data('gtmdata');
		dataLayer.push(gtmdata);
	},
	redirecturlstart: function() {
		$.ajax(location.href,{
			statusCode: {
				404: function() {
					get404Data();
				}
			}
		});
	},
	// events that should happen on every page
	all: function () {
		// Add to Cart
	    $('body').on('click', '.add-to-cart, .add-to-cart-global, .offer-add-to-cart', function () {
            var $ele = $(this);
			var gmtData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
			var qty = $ele.closest('.product-wrapper').find('.quantity-select').val();
			addToCart(gmtData, qty);
		});

		//Remove from Cart
		$('body').on('click', '.remove-product', function () {
            var $ele = $(this);
			var gmtData = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
			var qty = $ele.closest('.card').find('select.quantity').val();
			$('body').on('click', '#removeProductModal .cart-delete-confirmation-btn', function () {
				removeFromCart(gmtData, qty);
			});
		});

        // update GTM data attribute
		$('body').on('product:updateAddToCart', function (e, response) {
			$('button.add-to-cart, button.add-to-cart-global', response.$productContainer)
                .data('gtmdata', response.product.gtmData)
                .attr('data-gtmdata', JSON.stringify(response.product.gtmData));
		});

		$('body').on('click', '.recommendations .image-container a:not(.quickview), .recommendations .pdp-link a', function () {
            var $ele = $(this).closest('.product');
			var gtmdata = $ele.data('gtmdata') || $.parseJSON($ele.attr('data-gtmdata'));
			relatedProductClick(gtmdata);
		});
	}
};

/**
 * @param {String} productId The product ID
 * @description gets the data for a product click
 */
function productClick (productObject) {
	var obj = {
			'event': 'productClick',
			'ecommerce': {
				'click': {
					'actionField': {'list': 'Search Results'},
					'products': []
				}
			}
		};
	obj.ecommerce.click.products.push(productObject);
	dataLayer.push(obj);
}

/**
 * @param productId
 * @description Click event for add product to cart
 */
function addToCart (productObject, quantity) {
	var quantObj = {'quantity': quantity},
	obj = {
		'event': 'add_to_cart',
		'ecommerce': {
			'items': [],
			'currencyCode': 'USD'
		}
	};
	obj.ecommerce.items.push($.extend(productObject,quantObj));
	dataLayer.push(obj);
}

/**
 * @function removeFromCart
 * @description Click event for remove product from cart
 */
function removeFromCart (productObject, quantity) {
	var quantObj = {'quantity': quantity},
	obj = {
		'event': 'remove_from_cart',
		'currencyCode': 'USD',
        'ecommerce': {
            'items': []
        }
	};
	obj.ecommerce.items.push($.extend(productObject,quantObj));
	dataLayer.push(obj);
}

/**
 * @param {String} productId The product ID
 * @description gets the data for a product click
 */
 function relatedProductClick(productObject) {
	var obj = {
		'event': 'relatedProductClick',
		'currencyCode': 'USD',
		'relatedProductData': []
	};
	obj.relatedProductData.push(productObject);
	dataLayer.push(obj);
}

/**
 * @return {Object} Object containing checkout step data.
 */
 function get404Data() {
    var obj = {
        'event': '404',
        'eventData': {
            'destination': location.href,
            'previous_page': document.referrer,
            'category': '404 General Error'
        }
    };

    dataLayer.push(obj);
}

/**
 * @function pushEvent
 * @description Convenience method for creating a click event.
 * @param {String} event
 * @param {String} eventCategory
 * @param {String} eventAction
 * @param {String} eventlabel
 */
function pushEvent(event, eventCategory, eventAction, eventLabel) {
	dataLayer.push({
		'event': event,
		'eventCategory': eventCategory,
		'eventAction': eventAction,
		'eventLabel': eventLabel
	});
}

/**
 * @function init
 * @description Initialize the tag manager functionality
 * @param {String} nameSpace The current name space
 */
$(document).ready(function () {
	if (pageAction && events[pageAction]) {
		events[pageAction]();
	}
	events.all();
});

/**
 * listener for ajax events
 */
function gtmEventLoader() {
	try {
		$(document).ajaxSuccess(function(event, request, settings, data) {
			if (settings.dataTypes.indexOf('json') > -1) {
				if (data && '__gtmEvents' in data && Array.isArray(data.__gtmEvents)) {
					data.__gtmEvents.forEach(function gtmEvent(gtmEvent) {
						if(gtmEvent) { dataLayer.push(gtmEvent) }
					});
				}
			}
		});
		document.removeEventListener('DOMContentLoaded', gtmEventLoader);
	} catch (e) {
		console.error(e);
	}
}

/**
 * setup ajax event listener
 */
if (document.readyState === 'complete') {
	gtmEventLoader();
} else {
	document.addEventListener('DOMContentLoaded', gtmEventLoader);
}
